<template>
  <div id="mornitor">
    <div class="card">
      <div class="card-content" style="text-align: left;">
        <strong class="title is-4">
          จำนวนสาขา: {{branchs.length}}
          <button type="button" class="button is-small is-info" style="float:right;" @click="new_branch=true">
            <span class="mr-2 is-hidden-mobile">เพิ่มสาขา</span>
            <i class="fas fa-plus"></i>
          </button>
        </strong>
        <hr>
        <div class="has-text-centered" v-if="!branchs.length">
          <hr>คุณยังไม่ได้สร้างสาขา
        </div>
        <FormBranch :branchs="branchs" @edit_branch="edit_branch" v-else></FormBranch>
      </div>
    </div>

    <modal-branch :show="new_branch" :branch="branch" @submit="submit_branch" @close="new_branch=false; branch={};"></modal-branch>

  </div>
</template>

<script>
import FormBranch from '@/components/Form/Branch'
import ModalBranch from '@/components/Modal/Branch'

export default {
  name: 'MemberMornitor',
  components:{ FormBranch, ModalBranch },
  props: {
    userData: Object,
    loaded: Object,
    branchs: Array,
  },
  data(){
    return {
      new_branch: false,
      branch: {},
    }
  },
  created(){
    if(!this.loaded.get_branch){
      this.$emit('get_branch');
    }
  },
  methods:{
    edit_branch(val){
      this.branch = val;
      this.new_branch = true;
    },
    submit_branch(input){
      if(!input.id){
        this.submit_new_branch(input)
      }else{
        this.submit_edit_branch(input)
      }
      this.new_branch = false;
    },
    // submit_new_branch(input){
    //   let add_branch =  {
    //     id: Date.now(),
    //     u_id: this.userData.id,
    //     name: input.name,
    //     detail: input.detail,
    //     updated: "0000-00-00 00:00:00",
    //     created: "2021-09-12 23:21:43",
    //     deleted: 0
    //   }
    //   this.branchs.push(add_branch);
    //   this.$swal("","เพิ่มข้อมูลสาขาใหม่","success");
    // },
    // submit_edit_branch(input){
    //   Object.keys(input).forEach((key) => {
    //     this.branch[key] = input[key];
    //   });
    //   this.branch = {};
    //   this.$swal("","Successful Save","success");
    // },
  }
}
</script>
